/* eslint-disable vue/no-unused-components no-console */

<template>
  <div class="wordtable">
    <v-data-table
      id="virtual-scroll-table"
      v-scroll:#virtual-scroll-table="onScroll"
      :headers="headers"
      :items="wordsLimited"
      :hide-default-footer="true"
      :disable-pagination="true"
      class="elevation-1"
    >
      <template v-if="start > 0" v-slot:[`body.prepend`]>
        <tr>
          <td
            :colspan="headers.length"
            :style="'padding-top:' + startHeight + 'px'"
          ></td>
        </tr>
      </template>
      <template
        v-if="start + perPage < this.words.length"
        v-slot:[`body.append`]
      >
        <tr>
          <td
            :colspan="headers.length"
            :style="'padding-top:' + endHeight + 'px'"
          ></td>
        </tr>
      </template>
      <template v-slot:[`item.bild`]="{ item }">
        <router-link :to="{ name: 'word-detail', params: { id: item.id } }"
          ><v-lazy
            ><FirebaseStorageImg
              v-if="images && item.symbol && item.symbol.filePath"
              :url="item.symbol.filePath"
              :width="50"
            ></FirebaseStorageImg></v-lazy
        ></router-link>
      </template>
      <template v-slot:[`item.word`]="{ item }">
        <router-link :to="{ name: 'word-detail', params: { id: item.id } }">
          {{ item.word }}
        </router-link>
        <v-icon
          color="red"
          title="Nicht in alle Wörter anzeigen"
          v-if="item.hide_from_all_words"
          >mdi-checkbox-blank-circle-outline</v-icon
        >
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-icon color="green" v-if="item.active" @click="toggleItemActive(item)"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon color="red" @click="toggleItemActive(item)" v-else
          >mdi-close</v-icon
        >
      </template>
      <template v-slot:[`item.audio`]="{ item }">
        <FirebaseStorageAudio
          v-if="item.audios && item.audios[0] && item.audios[0].filePath"
          :url="item.audios[0].filePath"
        ></FirebaseStorageAudio>
        <v-icon color="red" v-else>mdi-audio-video-off</v-icon>
      </template>

      <template v-slot:[`item.video`]="{ item }">
        <v-icon
          color="green"
          v-if="item.videos && item.videos[0] && item.videos[0].filePath"
          @click="openVideo(item)"
          >mdi-video</v-icon
        >
        <v-icon color="red" v-else>mdi-video-off</v-icon>
        <span v-if="item.double_sign">x2</span>
      </template>
      <template v-slot:[`item.dictionaries`]="{ item }">
        <v-chip-group>
          <v-chip
            v-for="dictionary_id in item.dictionaries_ids"
            :key="dictionary_id"
            label
            >{{ getDictionaryFromId(dictionary_id) }}</v-chip
          ></v-chip-group
        >
      </template>
      <template v-slot:[`item.categories`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              {{ item.categories_ids ? item.categories_ids.length : "0" }}
              <v-icon>mdi-database</v-icon>
            </v-btn>
          </template>
          <v-list dense subheader>
            <v-subheader
              >{{
                item.categories_ids ? item.categories_ids.length : ""
              }}
              Kategorien:</v-subheader
            >
            <v-list-item
              dense
              v-for="category_id in item.categories_ids"
              :key="category_id"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="getCategoryFromId(category_id)"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tooltip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          elevation="0"
          :to="{ name: 'word-detail', params: { id: item.id } }"
        >
          <v-icon small class="mr-2"> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="videoDialog" max-width="500px">
      <v-card v-if="videoPreviewWord !== false">
        <v-card-title>{{ videoPreviewWord.word }}</v-card-title>
        <v-card-text>
          <video
            v-if="
              videoPreviewWord.videos &&
              videoPreviewWord.videos[0] &&
              videoPreviewWord.videos[0].filePath
            "
            v-firebase-storage-src="videoPreviewWord.videos[0].filePath"
            controls="controls"
            style="width: 100%"
          ></video>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style >
#virtual-scroll-table {
  max-height: 80vh;
  overflow: auto;
}
</style>

<script>
import FirebaseStorageImg from "./FirebaseStorageImg.vue";
import FirebaseStorageAudio from "./FirebaseStorageAudio.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    words: Array,
    images: Boolean,
  },
  data() {
    return {
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "bild",
        },
        {
          text: "Wort",
          align: "start",
          sortable: false,
          value: "word",
        },
        {
          text: "Veröffentlicht",
          align: "start",
          sortable: false,
          value: "active",
        },
        {
          text: "Audio",
          align: "start",
          sortable: false,
          value: "audio",
        },
        {
          text: "Video",
          align: "start",
          sortable: false,
          value: "video",
        },
        {
          text: "Wörterbücher",
          align: "start",
          sortable: false,
          value: "dictionaries",
        },
        {
          text: "Kategorien",
          align: "start",
          sortable: false,
          value: "categories",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      videoPreviewWord: false,
      videoDialog: false,
      start: 0,
      timeout: null,
      rowHeight: 24,
      perPage: 25,
    };
  },
  watch: {
    videoDialog: function (newValue) {
      if (!newValue) {
        // Closing
        this.videoPreviewWord = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      dictionaries: "dictionaries/getList",
      categories: "categories/getList",
    }),
    wordsLimited() {
      return this.words.slice(this.start, this.perPage + this.start);
    },
    startHeight() {
      return this.start * this.rowHeight - 32;
    },
    endHeight() {
      //   return 20;
      return this.rowHeight * (this.words.length - this.start);
    },
  },
  methods: {
    hasAudio: function (word) {
      return word.audios && word.audios[0] && word.audios[0].filePath;
    },
    getAudio: function (word) {
      if (this.hasAudio(word)) {
        // eslint-disable-next-line no-console
        console.log("hasaudio!", word);
        return word.audios[0].filePath;
      }
      return "";
    },
    hasVideo: function (word) {
      return word.videos && word.videos[0] && word.videos[0].filePath;
    },
    getDictionaryFromId: function (id) {
      const dictionary = this.dictionaries.find(
        (dictionary) => dictionary.id === id
      );
      if (dictionary) {
        return dictionary.name;
      }
    },
    getCategoryFromId: function (id) {
      const category = this.categories.find((category) => category.id === id);
      if (category) {
        return category.name;
      }
    },
    toggleItemActive: function (word) {
      var active = word.active === true;
      const updatedWord = { ...word, active: !active };

      this.$store.dispatch("word/saveWord", {
        id: word.id,
        word: updatedWord,
      });
    },

    playAudio: async function () {
      if (this.hasAudio) {
        if (this.audio === false) {
          const relPath = this.word.audios[0].filePath;
          const storagePath = await this.getStoragePath(relPath);
          this.audio = new Audio(storagePath);
        }
        if (this.audio_playing === false) {
          this.audio_playing = true;
          this.audio.play().then(() => {
            this.audio_playing = false;
          });
        }
      }
    },
    openVideo: function (word) {
      this.videoPreviewWord = word;
      this.videoDialog = true;
    },
    playVideo: function () {},
    onScroll(e) {
      // debounce if scrolling fast
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start =
          rows + this.perPage > this.words.length
            ? this.words.length - this.perPage
            : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },
  },
  components: { FirebaseStorageImg, FirebaseStorageAudio },
};
</script>