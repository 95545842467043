<template>
  <v-card>
    <router-link :to="{ name: 'word-detail', params: { id: word.id } }"
      ><v-card-title :title="word.id">{{ word.word }}</v-card-title>
    </router-link>
    <v-card-text>
      <!-- <pre>
            {{ word }}
        </pre
      > -->
      <router-link :to="{ name: 'word-detail', params: { id: word.id } }">
        <FirebaseStorageImg
          v-if="word.symbol && word.symbol.filePath"
          :url="word.symbol.filePath"
        ></FirebaseStorageImg>
      </router-link>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="videoDialog = !videoDialog" v-if="hasVideo" dark>
        <v-icon>mdi-video-outline</v-icon>
      </v-btn>
      <v-btn @click="playAudio" v-if="hasAudio" dark>
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="videoDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ word.word }}</v-card-title>
        <v-card-text>
          <video
            v-if="hasVideo"
            v-firebase-storage-src="word.videos[0].filePath"
            controls="controls"
            style="width: 100%"
          ></video>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import FirebaseStorageImg from "./FirebaseStorageImg.vue";
import { storage } from "@/plugins/firebase";
export default {
  components: { FirebaseStorageImg },
  data() {
    return {
      videoDialog: false,
      audio: false,
      audio_playing: false,
    };
  },
  props: {
    word: Object,
    linked: Boolean,
  },
  computed: {
    hasAudio: function () {
      return (
        this.word.audios && this.word.audios[0] && this.word.audios[0].filePath
      );
    },
    hasVideo: function () {
      return (
        this.word.videos && this.word.videos[0] && this.word.videos[0].filePath
      );
    },
  },

  methods: {
    playAudio: async function () {
      if (this.hasAudio) {
        if (this.audio === false) {
          const relPath = this.word.audios[0].filePath;
          const storagePath = await this.getStoragePath(relPath);
          this.audio = new Audio(storagePath);
        }
        if (this.audio_playing === false) {
          this.audio_playing = true;
          this.audio.play().then(() => {
            this.audio_playing = false;
          });
        }
      }
    },
    pauseAudio: function () {
      if (this.audio !== false && this.audio_playing === true) {
        this.audio.pause();
      }
    },
    getStoragePath: async function (path) {
      return await storage
        .ref()
        .child(path)
        .getDownloadURL()
        .then((url) => {
          return url;
        });
    },
  },
};
</script>