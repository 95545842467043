<template>
  <div>
    <v-icon @click="playAudio" v-if="!audio_playing">mdi-play</v-icon>

    <v-icon v-else @click="pauseAudio">mdi-pause</v-icon>
  </div>
</template>

<script>
import { storage } from "@/plugins/firebase";

export default {
  props: {
    url: { type: String, required: true },
    minimal: { type: Boolean, default: false },
  },
  data: function () {
    return {
      src: "",
      loaded: false,
      audio: false,
      audio_playing: false,
      audio_loaded: false,
      link: "",
    };
  },
  components: {},
  created: function () {
    // this.getLink();
  },
  methods: {
    pauseAudio: function () {
      this.audio.pause();
      this.audio_playing = false;
    },

    playAudio: async function () {
      // eslint-disable-next-line no-console
      console.log("play audio");
      if (!this.url || this.url === "") {
        // eslint-disable-next-line no-console
        console.error("no url");
        return;
      }
      if (!this.audio_loaded) {
        await this.getLink();

        this.audio = new Audio(this.link);
        this.audio.addEventListener("ended", () => {
          this.audio_playing = false;
        });
        this.audio_loaded = true;
      }

      if (this.audio_playing === false) {
        this.audio_playing = true;
        this.audio.play().then(() => {
          //   this.audio_playing = false;
        });
      }
    },
    getLink: async function () {
      // eslint-disable-next-line no-console
      console.log("getting link for " + this.url);
      return await storage
        .ref(this.url)
        .getDownloadURL()
        .then((link) => {
          this.link = link;
          this.loaded = true;
          return link;
        });
    },
  },
};
</script>