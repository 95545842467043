<template>
  <div class="words">
    <v-container v-if="loading">
      <v-overlay v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-banner> lädt </v-banner>
      </v-overlay>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="6">
          <h1>Wörter</h1>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="primary"
            dark
            class="mb-2 float-right"
            :to="{ name: 'word-detail-create' }"
          >
            Neues Wort
          </v-btn>
        </v-col>
      </v-row>

      <v-sheet>
        <v-row class="pa-4">
          <v-col cols="4">
            <v-text-field v-model="suche" label="Suche" dense></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="categories"
              v-model="selectedCategory"
              item-text="name"
              item-value="id"
              label="Kategorie"
              :return-object="true"
              :clearable="true"
              @change="selectCategory"
              dense
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="dictionaries"
              v-model="selectedDictionary"
              item-text="name"
              item-value="id"
              label="Wörterbuch"
              :return-object="true"
              :clearable="true"
              @change="selectDictionary"
              dense
            ></v-select>
          </v-col>
          <v-col cols="2" align="end">
            <v-btn-toggle v-model="display_mode" dense>
              <v-btn>
                <v-icon>mdi-table</v-icon>
              </v-btn>
              <v-btn>
                <v-icon>mdi-grid</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-switch v-model="showImages" label="Bilder Anzeigen"></v-switch>
          </v-col>
          <v-col cols="2">
            <v-switch v-model="symbolMissing" label="Symbol fehlt"></v-switch>
          </v-col>
          <v-col cols="2">
            <v-switch v-model="audioMissing" label="Audio fehlt"></v-switch>
          </v-col>
          <v-col cols="2">
            <v-switch v-model="videoMissing" label="Video fehlt"></v-switch>
          </v-col>
          <v-col cols="2">
            <v-switch v-model="incomplete" label="Unvollständig"></v-switch>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet v-if="display_mode === 0">
        <WordTable :words="filtered_words" :images="showImages"></WordTable>
      </v-sheet>
      <v-sheet v-if="display_mode === 1">
        <v-row>
          <v-col cols="2" v-for="word in filtered_words" :key="word.id">
            <WordTile :word="word"></WordTile
          ></v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

import { mapGetters } from "vuex";
import WordTile from "../components/WordTile.vue";
import router from "../plugins/router";
import WordTable from "../components/WordTable.vue";
// import WordTableVirtualScroll from "../components/WordTableVirtualScroll.vue";

export default {
  name: "words",
  components: {
    WordTile,
    WordTable,
    // WordTableVirtualScroll,
  },
  data: function () {
    return {
      selectedDictionaryIndex: {},
      selectedCategory: {},
      selectedCategoryId: "",
      selectedDictionary: {},
      selectedWordIndex: {},
      loading: true,
      display_mode: 0,
      suche: "",
      showImages: false,
      audioMissing: false,
      videoMissing: false,
      symbolMissing: false,
      incomplete: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      dictionaries: "dictionaries/getList",
      categories: "categories/getList",
      words: "words/getList",
    }),
    filtered_words: function () {
      let filteredWords = this.words;

      if (this.suche !== "") {
        filteredWords = filteredWords.filter(
          (word) =>
            word.word.toLowerCase().indexOf(this.suche.toLowerCase()) >= 0
        );
      }
      if (this.selectedDictionary?.id) {
        filteredWords = this.words.filter(
          (word) =>
            word.dictionaries_ids &&
            word.dictionaries_ids.indexOf(this.selectedDictionary.id) !== -1
        );
      }

      if (this.selectedCategory?.id) {
        filteredWords = this.words.filter(
          (word) =>
            word.categories_ids &&
            word.categories_ids.indexOf(this.selectedCategory.id) !== -1
        );
      }

      if (this.incomplete) {
        filteredWords = filteredWords.filter(
          (word) =>
            word.audios == undefined ||
            word.audios.length == 0 ||
            word.videos == undefined ||
            word.videos.length == 0 ||
            word.symbol == undefined ||
            word.symbol.filePath == undefined
        );
      }

      if (this.audioMissing) {
        filteredWords = filteredWords.filter(
          (word) => word.audios == undefined || word.audios.length == 0
        );
      }
      if (this.videoMissing) {
        filteredWords = filteredWords.filter(
          (word) => word.videos == undefined || word.videos.length == 0
        );
      }
      if (this.symbolMissing) {
        filteredWords = filteredWords.filter(
          (word) =>
            word.symbol == undefined || word.symbol.filePath == undefined
        );
      }

      return filteredWords;
    },
  },

  created: async function () {
    this.loading = false;
    if (this.$route.params.id) {
      console.log(this.$route.params.id);
      const selected = this.dictionaries.filter(
        (di) => di.id === this.$route.params.id
      )[0];
      console.log("selected", selected);
      this.selectDictionary(selected);
      this.selectedDictionary = selected;
      return;
    }
  },
  methods: {
    selectCategory: async function (selected) {
      this.selectedCategory = selected || {};
    },
    selectDictionary: async function (selected) {
      this.selectedDictionary = selected || {};
      try {
        if (selected && selected.id) {
          await router.push({ path: `/words/${selected.id}` });
        } else {
          await router.push({ path: `/words/` });
        }
      } catch (error) {
        console.error("naverror", { error });
      }
    },
  },
};
</script>
